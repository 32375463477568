<template>
  <div class="custom-empty">
    <el-empty :image-size="imageSize" :description="description"></el-empty>
  </div>
</template>
  
  <script >
import { defineComponent } from "vue";
export default defineComponent({
  name: "Empty",
  props: {
    imageSize: {
      type: Number,
      default: 60
    },
    description: {
      type: String,
      default: '暂无数据'
    }
  },
});
</script>
<style scoped lang="scss">
.custom-empty {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .el-empty {
    padding: 0;
  }
  :deep(.el-empty__description) {
    margin-top: 0.5vh;
    font-size: 0.4vw;
  }
}
</style>
  